import React, { useMemo } from "react";
import { Card, CardHeader } from "@fanduel/og-fe-library";
import Divider from "@/components/divider";
import OddsBtn, { useRedirectConfirmationModal } from "@/components/oddsBtn";
import RedirectToSportsbook from "@/events/Modals/RedirectToSportsbookModal";
import { getFormattedWeekdayWithTimeAndTimezone } from "@/utils/date";
import { Fixture, MatchupSVGProps } from "@/types/scoreBoard";
import { parseTeamSummary, parseWinProbability } from "@/utils/scores";
import Link from "next/link";
import { SPORTSBOOK_ODDS_CTA_CLICKED } from "@/constants/amplitude";
import { useAmplitude } from "@/utils/hooks/useAmplitude";
import Live from "./Live";
import MatchupSVG from "./MatchupSVG";
import TeamScore from "./TeamScore";
import TvSvg from "./TvSvg";
import styles from "./gameCard.module.scss";

const pick = (object: Record<string, string>, keys: string[]): string[] =>
  keys.map((key) => object[key]);

const GameCard = ({
  currentGameTime,
  scheduledStartTime,
  tvNetwork,
  sport,
  teams,
  eventUrl,
  gameStatus,
}: Fixture) => {
  const formattedStartTime = getFormattedWeekdayWithTimeAndTimezone(
    (scheduledStartTime ?? "").toLocaleString()
  );
  const gameTime = currentGameTime ?? "";
  const tvNetworkName = tvNetwork?.name ?? "";
  const sportName = sport?.name ?? "";
  const { logAmplitudeEvent } = useAmplitude();
  const fallbackUrl = "https://www.sportsbook.fanduel.com/";

  const {
    currentScore: hCurrentScore,
    fixtureSpread: hFixtureSpread,
    fixtureMoneyLine: hFixtureMoneyLine,
    fixtureTotal: hFixtureTotal,
    imgSrc: hImgSrc,
    abbreviation: hAbbreviation,
    primaryColor: hPrimaryColor,
    record: hRecord,
    winProbability: hWinProbabilityRaw,
    sportsbookLink: hSportsbookLink,
  } = parseTeamSummary(teams[0]);

  const {
    currentScore: aCurrentScore,
    fixtureSpread: aFixtureSpread,
    fixtureMoneyLine: aFixtureMoneyLine,
    fixtureTotal: aFixtureTotal,
    imgSrc: aImgSrc,
    abbreviation: aAbbreviation,
    primaryColor: aPrimaryColor,
    record: aRecord,
    winProbability: aWinProbabilityRaw,
    sportsbookLink: aSportsbookLink,
  } = parseTeamSummary(teams[1]);
  const svgProps: MatchupSVGProps = {
    sport: sportName,
    homeTeamImage: hImgSrc,
    homeTeamColor: hPrimaryColor,
    homeTeam: hAbbreviation,
    awayTeamImage: aImgSrc,
    awayTeamColor: aPrimaryColor,
    awayTeam: aAbbreviation,
  };

  const { aWinProbability, hWinProbability } = parseWinProbability(
    gameStatus,
    aWinProbabilityRaw,
    hWinProbabilityRaw
  );

  const showOdds = [
    ...pick(hFixtureSpread, ["odds", "value", "link"]),
    ...pick(hFixtureMoneyLine, ["odds", "link"]),
    ...pick(hFixtureTotal, ["odds", "value", "link"]),
    ...pick(aFixtureSpread, ["odds", "value", "link"]),
    ...pick(aFixtureMoneyLine, ["odds", "link"]),
    ...pick(aFixtureTotal, ["odds", "value", "link"]),
  ].every(Boolean);

  const tagDisplay = useMemo(() => {
    switch (gameStatus) {
      case "SCHEDULED":
        return <span>{formattedStartTime}</span>;
      case "IN_PROGRESS":
        return <Live gameTime={gameTime} />;
      case "FINAL":
        return "Final";
      case "DELAYED":
        return "Delayed";
      case "OTHER":
        return "Delayed";
      default:
        return "";
    }
  }, [gameStatus, gameTime, formattedStartTime]);

  const competition = eventUrl?.split("/").pop();
  const { onOddsClick, redirectToSportsbookProps } =
    useRedirectConfirmationModal(sportName, competition, "scoreboard");
  const onMatchupClicked = () => {
    logAmplitudeEvent(SPORTSBOOK_ODDS_CTA_CLICKED, {
      "Link URL": eventUrl ?? "",
      "Link Text": "matchup_clicked",
      Module: "scoreboard",
      Competition: competition ?? "",
      Sport: sportName,
    });
  };

  return (
    <Card className={styles.card}>
      <CardHeader className={styles.header}>
        <div className={styles.labelContainer}>
          {tvNetworkName && (
            <div className={styles.tvContainer}>
              <TvSvg />
              <span>{tvNetworkName}</span>
            </div>
          )}
          {tagDisplay}
        </div>
      </CardHeader>
      <Link
        onClick={onMatchupClicked}
        href={eventUrl ?? fallbackUrl}
        target="_blank"
        rel="noreferrer noopener"
        className={styles.matchupContainer}
      >
        <MatchupSVG {...svgProps} />
      </Link>
      <div className={styles.marketHeadingsContainer}>
        <div className={styles.marketHeadings}>
          <span>Spread</span>
          <span>Moneyline</span>
          <span>Total</span>
        </div>
      </div>
      <div className={styles.gameContainer}>
        <div className={styles.scoreContainer}>
          <TeamScore
            abbreviation={aAbbreviation}
            currentScore={aCurrentScore}
            record={aRecord}
            gameStatus={gameStatus}
            teamImageSrc={aImgSrc}
            winProbability={aWinProbability}
            teamSportsbookLink={aSportsbookLink}
            competition={competition}
            sport={sportName}
          />
          <div className={styles.marketsContainer}>
            <OddsBtn
              className={styles.oddsBtn}
              isSuspended={!showOdds}
              handicap={aFixtureSpread.value}
              odds={aFixtureSpread.odds}
              onClick={() => onOddsClick(aFixtureSpread.link)}
            />
            <OddsBtn
              className={styles.oddsBtn}
              isSuspended={!showOdds}
              odds={aFixtureMoneyLine.odds}
              onClick={() => onOddsClick(aFixtureMoneyLine.link)}
            />
            <OddsBtn
              className={styles.oddsBtn}
              isSuspended={!showOdds}
              handicap={aFixtureTotal.value}
              odds={aFixtureTotal.odds}
              onClick={() => onOddsClick(aFixtureTotal.link)}
            />
          </div>
        </div>
        <div className={styles.separator}>
          <div className={styles.symbol}>@</div>
          <Divider color="light" />
        </div>
        <div className={styles.scoreContainer}>
          <TeamScore
            abbreviation={hAbbreviation}
            currentScore={hCurrentScore}
            record={hRecord}
            gameStatus={gameStatus}
            teamImageSrc={hImgSrc}
            winProbability={hWinProbability}
            teamSportsbookLink={hSportsbookLink}
            competition={competition}
            sport={sportName}
          />
          <div className={styles.marketsContainer}>
            <OddsBtn
              className={styles.oddsBtn}
              isSuspended={!showOdds}
              handicap={hFixtureSpread.value}
              odds={hFixtureSpread.odds}
              onClick={() => onOddsClick(hFixtureSpread.link)}
            />
            <OddsBtn
              className={styles.oddsBtn}
              isSuspended={!showOdds}
              odds={hFixtureMoneyLine.odds}
              onClick={() => onOddsClick(hFixtureMoneyLine.link)}
            />
            <OddsBtn
              className={styles.oddsBtn}
              isSuspended={!showOdds}
              handicap={hFixtureTotal.value}
              odds={hFixtureTotal.odds}
              onClick={() => onOddsClick(hFixtureTotal.link)}
            />
          </div>
        </div>
      </div>
      <RedirectToSportsbook {...redirectToSportsbookProps} />
    </Card>
  );
};
export default React.memo(GameCard);
