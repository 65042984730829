import NumberFireIcon from "@/components/svg/NumberFireIcon";
import ToolTip from "@/components/horseRacing/toolTip/ToolTip";
import cx from "classnames";
import Styles from "./NumberFireProjection.module.scss";

type NumberFireProjectionProps = {
  winProbability: number;
  isRightSide?: boolean;
};

const NumberFireProjection = ({
  winProbability,
  isRightSide,
}: NumberFireProjectionProps) => (
  <div className={Styles.container}>
    <ToolTip
      content="numberFire Win Probability"
      classTooltip={cx(Styles.tooltip, isRightSide && Styles.rightSide)}
    />
    <NumberFireIcon width={12} height={12} />
    <span className={Styles.wpText}>{winProbability}%</span>
  </div>
);

export default NumberFireProjection;
